/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import translations from 'translations';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useDimensions } from 'hooks';
import { signinRequest, getAuthState } from 'redux-api/action/auth';
import { checkRedirectRequest } from 'redux-api/action/redirect';
import selectProfile from 'redux-api/reselect/profile';
import Loader from 'theme/loader';
import Disabled from 'theme/disabled';
import Page from 'theme/layouts/page';

function AuthSwitch({ children }) {
  const dispatch = useDispatch();
  const { width } = useDimensions();
  const router = useRouter();
  const { pathname, isReady, query } = router;

  const { isWaiting: isWaitingRedirect } = useSelector((state) => state.redirect);
  const {
    isGetWaiting: isWaitingProfile,
    payload: { guid },
  } = useSelector((state) => state.profile);
  const { authState } = useSelector((state) => state.auth);
  const { isEnabled, redirectPath } = useSelector(selectProfile);
  const { languageCode } = useSelector((state) => state.config);

  useEffect(() => {
    if (redirectPath) {
      try {
        const currentPath = router.pathname;
        const redirectBasePath = new URL(redirectPath, window.location.origin).pathname;

        if (currentPath !== redirectBasePath) {
          router.push(redirectPath);
        }
      } catch (error) {
        console.error("Invalid redirectPath:", redirectPath, error);
      }
    }
  }, [redirectPath, router]);

  const locale = useMemo(() => {
    return ['de', 'it', 'pt'].includes(languageCode) ? languageCode : 'en';
  }, [languageCode]);

  const content = useMemo(() => {
    if (authState !== 'SIGNEDIN' || isWaitingRedirect || (isWaitingProfile && !guid)) {
      return <Loader authState={authState} />;
    }

    if (!isEnabled) return <Disabled />;

    return <Page>{children}</Page>;
  }, [guid, isWaitingProfile, isEnabled, authState, children]);

  useEffect(() => {
    if (!isReady) return;

    dispatch(checkRedirectRequest(width));
    const { accessToken, idToken, refreshToken, timestamp, ...restQuery } = query;

    if (accessToken) {
      dispatch(signinRequest({ pathname, query: restQuery, accessToken, idToken, refreshToken }));
    } else {
      dispatch(getAuthState({ pathname }));
    }
  }, [dispatch, isReady, pathname, query.accessToken, width]);

  return (
    <IntlProvider messages={translations[locale]} locale={locale} defaultLocale="en">
      <div style={{ height: '100%' }}>
        <Helmet htmlAttributes={{ lang: locale }} />
        {content}
      </div>
    </IntlProvider>
  );
}

export default AuthSwitch;
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { space, position, flexbox, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { useSelector } from 'react-redux';
import selectProfile from 'redux-api/reselect/profile';
import Btn from 'components/btn-menu';
import { SymbolLogo } from 'theme/logo';
import Dropdown from 'theme/dropdown';
import DropdownItem from 'theme/dropdown/item';
import Feed from 'theme/icons/feed';
import Timelinechart from 'theme/icons/timelinechart';
import Card from 'theme/icons/card';
import Help from 'theme/icons/help';
import Lightning from 'theme/icons/lightning';
import ListUl from 'theme/icons/list-ul';
import BtnLogout from 'components/btn-logout';
import Text from 'theme/text';
import OnboardingChart from 'theme/onboarding';
import translations from 'translations';

const Wrapper = styled.div`
  ${position}
  ${layout}

  height: 100vh;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;

  z-index: 999;
`;

const Content = styled.div`
  ${space}

  overflow: auto;

  background: ${themeGet('colors.primary_500')};

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &:-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const Box = styled.div`
  ${flexbox}
  ${layout}
  ${space}

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapHelp = styled.div`
  ${space}

  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${themeGet('colors.white')};
`;

const WrapUpgrade = styled.div`
  ${space}
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${themeGet('colors.white')};
`;

const WrapItem = styled.a`
  width: 100%;
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
`;

const StyledA = styled.a`
  width: 100%;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
`;

function Menu() {
  const { pathname } = useRouter();

  const theme = useContext(ThemeContext);
  const { languageCode } = useSelector((state) => state.config);
  const {
    completitionPerc,
    payload: { company, onboard, hasPROPlan },
  } = useSelector(selectProfile);
  
  const {
    completition: { current },
  } = onboard || {};

  return (
    <Wrapper width={['96px', '96px', '96px', '165px']} top="0" bottom="0">
      <Content pt={['s', 's', 's', 'l']}>
        <Box flexDirection="column">
          {company?.code === 'SL_DE' ? (
            <img src="/images/bookrix_logo.png" width="60" height="60" alt="Logo BookRix" />
          ) : (
            <SymbolLogo
              style={{
                color: themeGet('colors.white')({ theme }),
                display: 'block',
                height: ['42px', '42px', '42px', '64px'],
                mb: ['xs', 'xs', 'xs', '0'],
              }}
            />
          )}
          <Btn
            title="dashboard"
            style={{
              mt: 'l',
            }}
            isFocused
            route={['/', '/dashboard', '/home']}
          >
            <Feed />
            <Text
              fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              lineHeights={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              fontWeight="normal"
              color="inherit"
            >
              {translations[languageCode].dashboard}
            </Text>
          </Btn>
          <Btn title="services" route={['/services']}>
            <ListUl />
            <Text
              fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              lineHeights={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              fontWeight="normal"
              color="inherit"
            >
              {translations[languageCode].Services}
            </Text>
          </Btn>
          <Btn title="analytics" route={['/analytics']}>
            <Timelinechart />
            <Text
              fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              lineHeights={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              fontWeight="normal"
              color="inherit"
            >
              {translations[languageCode].analytics}
            </Text>
          </Btn>
          <Btn title="billing" route={['/billing']}>
            <Card />
            <Text
              fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              lineHeights={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              fontWeight="normal"
              color="inherit"
            >
              {translations[languageCode].billing}
            </Text>
          </Btn>
        </Box>
        <Box flexDirection="column" mt="m">
          {company && company.code === 'SL_US' && !hasPROPlan && (
            <a href="https://www.streetlib.com/pricing/" target="_blank" rel="noopener noreferrer">
              <WrapUpgrade>
                <Lightning color="#EF9800" />
                <Text
                  fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
                  lineHeights={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
                  fontWeight="normal"
                  color="inherit"
                >
                  {translations[languageCode].upgrade}
                </Text>
              </WrapUpgrade>
            </a>
          )}

          <Dropdown
            placement="topRight"
            options={[
              <WrapItem
                title={translations[languageCode].faq}
                href="https://help.streetlib.com"
                key="help"
              >
                <DropdownItem label={translations[languageCode].faq} />
              </WrapItem>,
              <WrapItem
                title={translations[languageCode].community}
                href={
                  company?.code === 'SL_IT'
                    ? 'https://community.streetlib.it/join?invitation_token=fb074f6465dee2de3ec0d2d29446de14ed7ccc87-e595924a-07b0-44bc-8601-c10dfd7e59dd'
                    : 'https://community.streetlib.com/join?invitation_token=113f26bd56f112a28d7a278e6f7f00de03670a73-1d372b5c-560e-45f0-b99e-3bf99ca7cf16'
                }
                key="community"
              >
                <DropdownItem label={translations[languageCode].community} />
              </WrapItem>,
              <WrapItem
                title={translations[languageCode]['privacy policy']}
                href="https://www.streetlib.com/privacy"
                key="privacy"
              >
                <DropdownItem label={translations[languageCode]['privacy policy']} />
              </WrapItem>,
            ]}
          >
            <WrapHelp mb="s">
              <Help />
              <Text
                fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
                lineHeights={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
                fontWeight="normal"
                color="inherit"
              >
                {translations[languageCode].help}
              </Text>
            </WrapHelp>
          </Dropdown>

          <BtnLogout />

          {current !== 100 && (
            <Link href="/onboarding">
              <StyledA>
                <OnboardingChart
                  disabled={pathname === '/onboarding'}
                  percent={completitionPerc}
                  label={translations[languageCode]['On Boarding']}
                />
              </StyledA>
            </Link>
          )}
        </Box>
      </Content>
    </Wrapper>
  );
}

export default Menu;

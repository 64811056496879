import React from 'react';

function Lightning({ color = 'currentColor' }) {
  return (
    <svg width="29" height="28" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5738 71.06C27.0877 70.8548 26.6839 70.4937 26.4258 70.0335C26.1678 69.5734 26.0703 69.0404 26.1488 68.5187L30.4713 40.375H19C18.6364 40.3848 18.2753 40.3109 17.9447 40.159C17.6141 40.0071 17.3228 39.7814 17.0933 39.4991C16.8638 39.2168 16.7023 38.8856 16.6211 38.5309C16.5399 38.1763 16.5412 37.8078 16.625 37.4537L23.75 6.57874C23.8754 6.04739 24.18 5.57544 24.6126 5.24236C25.0452 4.90928 25.5793 4.7354 26.125 4.74999H49.875C50.2298 4.74879 50.5804 4.8271 50.901 4.97916C51.2216 5.13123 51.504 5.3532 51.7275 5.62874C51.9542 5.90741 52.1147 6.23386 52.197 6.58353C52.2793 6.93321 52.2812 7.29699 52.2025 7.64749L48.0938 26.125H59.375C59.8202 26.1241 60.2566 26.2483 60.6345 26.4835C61.0124 26.7186 61.3167 27.0553 61.5125 27.455C61.6827 27.8386 61.7482 28.2605 61.7023 28.6777C61.6564 29.0949 61.5008 29.4925 61.2513 29.83L30.3763 70.205C30.1676 70.5143 29.889 70.7701 29.563 70.9517C29.237 71.1332 28.8729 71.2354 28.5 71.25C28.1823 71.2435 27.8684 71.1791 27.5738 71.06ZM42.1563 30.875L46.9063 9.49999H28.025L21.9925 35.625H36.0288L32.2525 60.04L54.625 30.875H42.1563Z"
        fill={color} // Usa il colore passato come prop
      />
    </svg>
  );
}

export default Lightning;
